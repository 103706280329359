
import { isMultiChoice } from '@/helpers';
import { Question } from '@/ts/interfaces/Question';
import { defineComponent } from '@vue/runtime-core';
import { getParent, get, assign, addCondition, removeOrigin, refreshTree, deleteOption, openSettings, toggleChildren } from './module';
import OptionList from './SettingsOptionList.vue';

const closeSettings = () => assign({ set: null });

export default defineComponent({
    mounted: () => window.addEventListener('click', closeSettings),
    unmounted: () => window.removeEventListener('click', closeSettings),
    components: { OptionList },
    data: () => ({}),
    computed: {
        set(){
            return this.$store.state.questions!.set;
        },
        activePath(){
            return this.$store.state.questions!.activePath;
        },
        cats(){
            return this.$store.state.questions!.cats;
        },
        comparisons(){
            return this.$store.state.questions!.comparisons;
        },
        fileTypes(){
            return this.$store.state.questions!.fileTypes;
        },
        types(){
            return this.$store.state.questions!.types;
        },
        units(){
            return this.$store.state.questions!.units;
        },
        data(){
            return this.set?.data;
        },
        parent(){
            return this.set ? getParent(this.$store.state.questions!, this.set.level) : {};
        },
        origin(){
            return this.data?.origin?.data;
        },
        howmany(){
            if(!this.origin)
                return null

            const { length } = this.origin.children;
            return length === 1 ? 'שאלה אחת עוקבת' : `${length} שאלות עוקבות`;
        },
        isMultiChoice(){
            return isMultiChoice(this);
        },
        originIsMultiChoice(){
            return this.origin?.type && isMultiChoice(this.origin);
        },
        type(){
            return this.data?.type;
        }
    },
    methods: {
        addCondition,
        removeOrigin,
        deleteOption,
        openSettings,
        toggleChildren(data?: { level: 'origin', type: Question['type'] }){
            if(data) toggleChildren(data);

            const { level, index } = this.set!;
            const { type } = this.set!.data;
            toggleChildren({ level, index, type });
            assign({ set: null });
        },
        changeMode(){
            const set = this.set!;
            Object.assign(set.data, { valid: get.valid(), origin: {} });
            assign({
                mode: 'choose',
                retainSet: { set, activePath: [...this.activePath], pagination: [...this.$store.state.questions!.pagination] },
                set: null,
            });
        },
        resetValid(item: 'text' | 'cat' | 'type' | 'condition' | 'fileType'){
            this.data!.valid[item] = true;
        },
        resetParams(){
            this.resetValid('type');
            const { set, activePath } = this;
            Object.assign(set!.data, { allow_alt: false, fileType: null, unit: '' });
            if(!this.isMultiChoice){
                if(activePath.length && set!.level < activePath.length)
                    refreshTree(set!.level);
            }else{
                for(const child of set!.data.children){
                    const isArray = Array.isArray(child.condition);
                    if(set!.data.type === 'num' && !isArray)
                        child.condition = [get.condition()];
                    else if(isArray)
                        child.condition = {};
                    child.conditionType = 'or';
                }
            }
        },
    }
});
