<template>
    <div id='settingsModal'>
        <div id='settings' v-if='set !== null' @click.stop>
            <div class='title'>
                <div class='X' @click='$store.commit("questions/assign", { set: null })'>X</div>
                <div class='text'>הגדרות שאלה</div>
            </div>
            <div class='wrapper' v-if='set.level >= 1'>
                <div class='box childData'>
                    <label>מקור השאלה:
                        <select v-model='data.getFrom' @change='data.canBeTop = false'>
                            <option value='new'>שאלה חדשה</option>
                            <option value='tree'>שאלה מהעץ</option>
                            <!--option value='db'>שאלה מהמאגר</option-->
                        </select>
                    </label>
                    <label v-show='data.getFrom === "new"'>
                        יכולה לשמש כראש עץ?
                        <input type='checkbox' v-model='data.canBeTop' />
                    </label>
                    <label v-show='parent.type.startsWith("checkbox")'>
                        סוג תנאי המעקב:
                        <select v-model='data.conditionType'>
                            <option value='or'>OR</option>
                            <option value='and'>AND</option>
                        </select>
                    </label>
                </div>
                <div class='box childData conditions' :class='{ error: !data.valid.condition }'>
                    <div v-if='parent.type === "num"' class='label' style='align-items: flex-start'>
                        <div class='column center'>
                            <span><u>תנאים</u>:</span>
                            <a @click='addCondition'>לחצו להוספה</a>
                        </div>
                        <div class='column'>
                            <div v-for='(c, index) in data.condition' :key='index'>
                                <img
                                    class='trash' :class='{ inactive: !c.ditch }'
                                    :src='"/images/trash-black.png"'
                                    width='12'
                                    @click='deleteOption({ index, elem: "condition" })'
                                />
                                תשובה
                                <custom-select v-model='c.type' :options='comparisons' />
                                <span v-show='c.type === "><"' class='range'>
                                    בין <input v-model='c.x'/> ל-
                                </span>
                                <input v-model='c.val'/>
                            </div>
                        </div>
                    </div>
                    <div v-else class='label'>
                        <u>בעקבות התשובות</u>:
                        <div class='conditionList'>
                            <div v-for='(option,index) in parent.options' :key='index'>
                                <input type='checkbox' v-model='data.condition[index]'/>
                                {{ option.val }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='treeContainer' v-if='data.getFrom === "tree"'>
                <div class='box tree' v-if='!data.origin'>
                    כאשר תלחצו על המשך, תעברו למצב בחירת שאלה. במצב זה, סימני גלגל השיניים הוחלפו בסימני חץ. חפשו את השאלה שתרצו להשתמש בה - לחיצה על סימן החץ שלה תבחר את השאלה, ותחזיר אתכם להגדרות השאלה הנוכחית.
                    <button type='button' @click='changeMode'>המשך</button>
                    <div v-if='data.valid.tree' :style='{ color: "red" }'>לא נבחרה שאלה</div>
                </div>
                <div class='box tree selected' v-else>
                    <div class='title'>
                        <b>השאלה שנבחרה:</b>
                        <u>{{ origin.text || 'ללא שם' }}</u>
                    </div>
                    <div v-if='origin.canBeTop'>
                        <u>קטגוריה</u>:
                        <span v-if='origin.cat !== null'>
                            {{ cats[origin.cat] }}
                        </span>
                        <span v-else>טרם נבחרה</span>
                    </div>
                    <div>
                        <u>סוג</u>:
                        <span v-if='origin.type !== null'>
                            {{ types[origin.type] }}
                            <span v-if='origin.fileType !== null'> - {{ fileTypes[origin.fileType] }}</span>
                        </span>
                        <span v-else>טרם נבחר</span>
                    </div>
                    <div v-if='origin.type === "num" && origin.unit !== null'>
                        <u>יחידת מדידה</u>:
                        {{ units[origin.unit] }}
                    </div>
                    <div v-if='origin.allow_alt'>
                        <img src='/images/check-icon.png' width='10' height='10'/>
                        <span class='alt'>אפשרות</span>
                        <span v-if='originIsMultiChoice'>לסמן "אחר"</span>
                        <span v-else>לאמצעי אינפוט חלופי</span>
                    </div>
                    <div v-if='originIsMultiChoice'>{{ howmany }}</div>
                    <div class='buttonBar'>
                        <div @click.stop='removeOrigin'>
                            <img src='/images/trash.png' width='18'/>
                        </div>
                        <div @click.stop='openSettings'>
                            <img src='/images/gear-icon.png' width='17'/>
                        </div>
                        <div
                            :class="{ transparent: originIsMultiChoice }"
                            @click='toggleChildren({ level: "origin", type: origin.type })'
                        >
                            <img src='/images/tree-icon.png' width='22'/>
                        </div>
                    </div>
                </div>
            </div>
            <div class='wrapper' v-if='data.getFrom==="new"'>
                <div class='box data'>
                    <label class='questionText'>
                        שאלה:
                        <textarea v-model='data.text' :class='{ error: !data.valid.text }' @input='resetValid("text")' />
                    </label>
                </div>
                <div class='box data params'>
                    <label v-show='data.canBeTop' :class='{ error: !data.valid.cat }'>
                        קטגוריה:
                        <custom-select v-model='data.cat' :options='cats' @change='resetValid("cat")' />
                    </label>
                    <label :class='{ error: !data.valid.type }'>
                        סוג:
                        <custom-select v-model='data.type' :options='types' @change='resetParams' />
                    </label>
                    <label :class='{ error: !data.valid.fileType }' v-if='data.type == 4'>
                        סוג הקובץ:
                        <custom-select v-model='data.fileType' :options='fileTypes' @change='resetValid("fileType")' />
                    </label>
                    <label v-else-if='data.type === "num"'>
                        יחידת מדידה:
                        <custom-select v-model='data.unit' :options='units' :initial='{ value: "", display: "לא רלוונטי" }' />
                    </label>
                    <label v-if='!["response", "objective", "date"].includes(data.type)'>
                        <span v-if='isMultiChoice'>אפשרות לסמן "אחר"?</span>
                        <span v-else>אפשרות לאמצעי אינפוט חלופי?</span>
                        <input type='checkbox' v-model='data.allow_alt'/>
                    </label>
                    <label v-if='data.type.startsWith("checkbox")'>
                        אפשרות לסמן "אף אחד מהנ"ל"?
                        <input type='checkbox' v-model='data.na'/>
                    </label>
                    <div style='cursor: pointer' v-if='isMultiChoice || data.type === "num"' @click='toggleChildren()'>
                        <div :class='{ redDot: !data.valid.children }'></div>
                        <img src='/images/tree-icon.png' width='22'/>
                    </div>
                </div>
                <option-list
                    v-if='isMultiChoice'
                    text='אפשרויות'
                    elem='options'
                />
                <option-list
                    v-else-if='data.type === "objective"'
                    text='פרמטרים'
                    elem='params'
                />
                <div v-else-if='data.type === "response"' class='box data'>
                    <label class='questionText'>
                        קישור (אם רלוונטי):
                        <textarea v-model='data.link' />
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { isMultiChoice } from '@/helpers';
import { Question } from '@/ts/interfaces/Question';
import { defineComponent } from '@vue/runtime-core';
import { getParent, get, assign, addCondition, removeOrigin, refreshTree, deleteOption, openSettings, toggleChildren } from './module';
import OptionList from './SettingsOptionList.vue';

const closeSettings = () => assign({ set: null });

export default defineComponent({
    mounted: () => window.addEventListener('click', closeSettings),
    unmounted: () => window.removeEventListener('click', closeSettings),
    components: { OptionList },
    data: () => ({}),
    computed: {
        set(){
            return this.$store.state.questions!.set;
        },
        activePath(){
            return this.$store.state.questions!.activePath;
        },
        cats(){
            return this.$store.state.questions!.cats;
        },
        comparisons(){
            return this.$store.state.questions!.comparisons;
        },
        fileTypes(){
            return this.$store.state.questions!.fileTypes;
        },
        types(){
            return this.$store.state.questions!.types;
        },
        units(){
            return this.$store.state.questions!.units;
        },
        data(){
            return this.set?.data;
        },
        parent(){
            return this.set ? getParent(this.$store.state.questions!, this.set.level) : {};
        },
        origin(){
            return this.data?.origin?.data;
        },
        howmany(){
            if(!this.origin)
                return null

            const { length } = this.origin.children;
            return length === 1 ? 'שאלה אחת עוקבת' : `${length} שאלות עוקבות`;
        },
        isMultiChoice(){
            return isMultiChoice(this);
        },
        originIsMultiChoice(){
            return this.origin?.type && isMultiChoice(this.origin);
        },
        type(){
            return this.data?.type;
        }
    },
    methods: {
        addCondition,
        removeOrigin,
        deleteOption,
        openSettings,
        toggleChildren(data?: { level: 'origin', type: Question['type'] }){
            if(data) toggleChildren(data);

            const { level, index } = this.set!;
            const { type } = this.set!.data;
            toggleChildren({ level, index, type });
            assign({ set: null });
        },
        changeMode(){
            const set = this.set!;
            Object.assign(set.data, { valid: get.valid(), origin: {} });
            assign({
                mode: 'choose',
                retainSet: { set, activePath: [...this.activePath], pagination: [...this.$store.state.questions!.pagination] },
                set: null,
            });
        },
        resetValid(item: 'text' | 'cat' | 'type' | 'condition' | 'fileType'){
            this.data!.valid[item] = true;
        },
        resetParams(){
            this.resetValid('type');
            const { set, activePath } = this;
            Object.assign(set!.data, { allow_alt: false, fileType: null, unit: '' });
            if(!this.isMultiChoice){
                if(activePath.length && set!.level < activePath.length)
                    refreshTree(set!.level);
            }else{
                for(const child of set!.data.children){
                    const isArray = Array.isArray(child.condition);
                    if(set!.data.type === 'num' && !isArray)
                        child.condition = [get.condition()];
                    else if(isArray)
                        child.condition = {};
                    child.conditionType = 'or';
                }
            }
        },
    }
});
</script>

<style lang='scss' scoped>
#settingsModal {
	@include flexbox(center-all);
    justify-self: flex-start;
}
#settings {
	min-height: 35vh;
	min-width: 80vw;
	position: fixed;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 2rem;
	padding: 1.25rem;
	top: 13vh;
	background-color: rgba(255, 251, 240, 0.9);
	border-radius: $corner;
	@include shadow(0.125,0.25);

	& .wrapper {
		display: contents;
	}

	& .treeContainer {
		grid-column-end: span 3;
		@include flexbox(center-all);
	}

	& .error:not(.conditions) select, & textarea.error, & #settings input.error, & .box.error {
		border: 1px red solid
	}

	& .title {
		grid-column-end: span 3;

		& .text {
			width: 20%;
			padding: 0.3rem 2rem;
			margin: 0 auto;
			text-align: center;
			border-radius: $corner;
			border: 0.5px black solid;
			@include shadow;
			background-color: $bgOne;
		}
		& .X {
			position: absolute;
			right: 1rem;
			top: 0.2rem;
			font-weight: bold;
			font-size: 1.25rem;
			cursor:pointer;
		}
	}
	& .box {
		@include flexbox(flex-start,center,column);
		padding: 0.3rem 1.25rem;
		border-radius: $corner;
		@include shadow;
		background-color: $bgOne;

		& select {
			margin-right: 0.5rem;
		}

		&.data {
			min-height: 13rem;
		}

		&.childData {
			min-height: 7rem;
		}

		&.params {
			justify-content: space-around;
		}

		&.conditions {
			grid-column-end: span 2;
			align-items: flex-start;
		}

		&.tree {
			min-width: 16rem;
			max-width: 50vw;
			flex-wrap: wrap;
			text-align: center;

			&.selected {
				padding: 0;
				& > div {
					padding: 0.5rem 0.5rem 0;
				}
				& > div:last-child {
					padding: 1rem 0 0;
				}
			}

			& button { margin: 1rem; }
		}
		& .text {text-align: left; padding-left: 10px;}
		& .trash	{
			cursor: pointer;
			position: relative;
			top: 3px;
			&.inactive {opacity: 0.3}
		}
		& .column {
			@include flexbox(center,column);
			margin: 0.3rem;

			& .trash {
				margin: 0 1rem 0.25rem 0.75rem;
			}
			& .range {
				@include flexbox(center-all);
				margin: 0 0.75rem 0 0.25rem;
			}
			&.center {
				margin-top: 0.6rem;
				align-items: center;
			}
			& input {width: 3rem; margin: 0 0.75rem;}
			& select {
				margin-right: 0.5rem;
			}
			& div {
				display: flex;
				align-items: center;
				margin-bottom: 0.75rem;
			}
		}
		& .conditionList {
			max-height: 20vh;
			overflow-y: auto;
		}
	}

	& .questionText {
		@include flexbox(center-all, column);
		height: 100%;
		width: 100%;

		& textarea	{
			margin-right: 10px;
			width: 90%;
			height: 80%;
			border-radius: $corner;
			border-width: 1px;
		}
	}
	& select {max-width: 130px}

	& .options {
		padding: 0.3rem 0;
	}

	& label, .label {
		@include flexbox(false,center);
		padding: 10px;
	}
	& .conditionList {
		@include flexbox(false,false,wrap);
		max-width: 80%;
		margin-right: 0.75rem;
		& div {margin-right: 0.75rem; padding-right: 2rem; text-indent: -1.75rem;}
	}
	& input { &[type='checkbox'] { margin-left: 0.75rem; } }
}
</style>
