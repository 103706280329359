<template>
    <div :style='{ width: "100%" }'>
        <div id='newQuestions'>
            <tree-row :level='1' :children='questions' />
        </div>
        <settings-modal v-if='showSettings' />
    </div>
    <saves-bar />
</template>

<script lang='ts'>
/*
    -שמירת טיוטה - לשפר UX
    -intake - default without!
    -אפשרות להריץ שאלון של מטופל, כמו במחקר
*/
import SettingsModal from './SettingsModal.vue';
import questionsModule, { setSaves } from './module';
import { defineComponent } from '@vue/runtime-core';
import SavesBar from './SavesBar.vue';

export default defineComponent({
    async beforeMount(){
        if(!this.$store.hasModule('questions'))
            this.$store.registerModule('questions', questionsModule);

        const saves = await this.$request('/questions/saves');
        setSaves(saves.body);
    },
    computed: {
        questions(){
            return this.$store.state.questions!.questions;
        },
        showSettings(){
            return Boolean(this.$store.state.questions!.set);
        }
    },
    components: { SettingsModal, SavesBar }
});
</script>

<style lang='scss'>
#newQuestions {
	display: grid;
	grid-template-columns: 5fr 2fr repeat(3, 13fr) 2fr;
    align-items: stretch;
    align-self: flex-start;
	justify-items: center;
	width: 100%;
	padding: 2rem 0;

	& .item {
		width: 100%;
		@include flexbox(flex-end, center, column)
	}

	& .wrapper {
		display: contents;
	}
}

.buttonBar {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(2rem,1fr));
	grid-template-rows: 1fr;
	width: 100%;
	& > * {
		@include flexbox(center-all);
		padding: 0.3rem;
		cursor: pointer;

		&.transparent { cursor: default; opacity: 0.2; }
		&:hover		  { background-color: #fffbf0; }
		&.stay {
			@include shadow(0.1,0.125,0.1rem);
			background-color: #fffbf0;
		}
	}
	& :first-child { border-bottom-right-radius: $corner; }
	& :last-child  { border-bottom-left-radius: $corner; }
}

.redDot {
	position: relative;
	right: -1rem;
	top: -0.6rem;
	margin-left: -0.5rem;
	height: 0.5rem;
	width: 0.5rem;
	border-radius: 0.25rem;
	background-color: red;
}

div.redDot {
	right: -0.6rem;
	top: 0;
	margin-bottom: -0.3rem;
}
</style>
