<template>
    <teleport to='#navSlot'>
        <div class='links'>
            <button @click='dialogs.load = true'>
                <span class='material-icons-outlined load'>download</span>
                טעינת טיוטה
            </button>
            <button @click='dialogs.draft = true'>
                <span class='material-icons-outlined'>save</span>
                שמירת טיוטה
            </button>
            <a @click='submit()'>סיום</a>
        </div>
    </teleport>
    <dialog-component :open='dialogs.draft' @close='dialogs.draft = false'>
        <template #title>שמירת טיוטה</template>
        <div id='draftDialog'>
            <label>
                שם הטיוטה
                <input v-model='saveName' />
            </label>
            <div id='draftData' v-if='currentDraft'>
                <div>עדכון אחרון:</div><div>{{ currentDraft.updatedAt }}</div>
            </div>
            <div>
                <button @click='submit(true)'>שמירה</button>
            </div>
        </div>
    </dialog-component>
    <dialog-component :open='dialogs.load' @close='dialogs.load = false'>
        <template #title>טעינת טיוטה</template>
        <div id='loadDialog'>
            <div v-for='save of saves' :key='save.id' @click='openSave(save.id)'>
                <div class='material-icons-outlined deleteDraft'>delete</div>
                <div>{{ save.name ?? save.created_at }}</div>
                <div>{{ formatForDisplay(save.updated_at) }}</div>
            </div>
        </div>
    </dialog-component>
    <dialog-component :open='dialogs.validation' @close='dialogs.validation = false'>
        <template #title>אופס!</template>
        <div id='validationDialog'>
            <div>
                נראה שלא כל הפרטים הנחוצים מולאו כראוי.
                {{ $parse('חפש', 'חפשי') }} את הנקודות האדומות -
                הן מציינות היכן חסר משהו.
            </div>
            <button @click='dialogs.validation = false'>אישור</button>
        </div>
    </dialog-component>
</template>

<script lang='ts'>
import { isMultiChoice, isMultiChoiceOrNum } from '@/helpers';
import { newQuestion } from '@/ts/state/NewQuestionsState';
import { defineComponent } from '@vue/runtime-core';
import { format } from 'date-fns';
import { getChildren, get } from './module';

type data = { saveId: string | null, saveName: string, dialogs: { draft: boolean, load: boolean, validation: boolean } }

export default defineComponent({
    data: (): data => ({ saveId: null, saveName: '', dialogs: { draft: false, load: false, validation: false } }),
    computed: {
        currentDraft(){
            if(!this.saveId) return null;
            const currentDraft = this.$store.state.questions?.saves.find(save => save.id === this.saveId);
            if(!currentDraft) return null;

            return {
                createdAt: this.formatForDisplay(currentDraft.created_at),
                updatedAt: this.formatForDisplay(currentDraft.updated_at)
            }
        },
        saves(){
            return this.$store.state.questions?.saves || [];
        },
        questions(){
            return this.$store.state.questions?.questions || [];
        },
    },
    methods: {
        formatForDisplay: (date: string) => format(new Date(date), 'd/M/Y, HH:mm'),
        openSave(saveId: string){
            const save = this.saves.find(s => s.id === saveId)!;
            this.$store.commit('questions/assign', { questions: JSON.parse(save.questions), saveId, pagination: [0], activePath: [], set: null });
            this.saveCorrections();
            Object.assign(this, { saveName: save.name, saveId });
            this.dialogs.load = false;
        },
        saveCorrections(questions?: newQuestion[], level = 0){
            if(!questions)
                questions = this.questions;

            for(let question of questions){
                const nullToEmpty = (item: any) => item === null ? '' : item;
                for(let p in question){
                    let prop = p as keyof newQuestion;
                    question[prop] = nullToEmpty(question[prop]) as never;
                    if(prop === 'params' || prop === 'options')
                        for(let i of question[prop])
                            i.val = nullToEmpty(i.val);
                }
                /*FOR OLD SAVES*/
                if(level > 0 && typeof question.canBeTop === 'undefined')
                    question.canBeTop = false;
                if(level > 0 && question.getFrom === 'tree' && question.origin){
                    const { level, activePath, index } = question.origin;
                    question.origin.data = getChildren(this.$store.state.questions!, level, activePath)[index];
                }
                if(question.children.length)
                    this.saveCorrections(question.children, level+1);
            }
        },
        async submit(isDraft = false){
            const draftMode = isDraft ? (this.currentDraft ? 'current' : 'new') : null;
            if(!draftMode && !this.validate()){
                this.dialogs.validation = true;
                return this.$store.commit('questions/refreshTree', 0);
            }

            let toastText;
            type payload = { questions: newQuestion[], saveId?: string, name: string };
            try{
                const endpoint = `/questions/${draftMode ? 'save' : 'submit'}`;
                const payload: payload = { questions: this.questions, name: this.saveName };
                if(draftMode === 'current' && this.saveId)
                    payload.saveId = this.saveId;
                let response = await this.$request(endpoint, 'put', payload);

                if(!draftMode){
                    toastText = 'השאלות נשמרו במאגר בהצלחה';
                    this.$router.push('/questions');   
                }else{
                    toastText = 'הטיוטה נשמרה בהצלחה';
                    this.$store.commit('questions/assign', { saves: response.body });
                }        
            }catch(error){
                console.error(error);
                toastText = 'קרתה תקלה. לא הצלחנו לשמור את השאלות';
                if(!draftMode)
                    toastText += '. אם היא נמשכת, שמרו את השאלות כטיוטה ועדכנו אותנו בתקלה';
            }finally{
                this.$store.commit('setToast', { text: toastText });
                this.dialogs.draft = false;
            }
        },
        validate: function(questions?: newQuestion[], parent: newQuestion | null = null){
            questions = questions ?? this.questions;
            let out = true, remove = [];
            for(let q in questions){
                const question = questions[q];
                const valid = question.getFrom === 'new'
                    ? this.validateNewQuestion(question, parent)
                    : {
                        is: typeof question.origin?.data !== 'undefined',
                        children: true,
                        text: true,
                        cat: true,
                        type: true,
                        condition: true,
                        options: [true],
                        params: [true],
                        fileType: true
                    }
               
                if(valid === null){
                    remove.unshift(q);
                    continue;
                }

                if(question.getFrom === 'new' && question.children.length)
                    valid!.children = this.validate(question.children, question);

                questions[q].valid = valid!;
                if(!valid!.is || !valid!.children)
                    out = false;
            }
            for(let r of remove)
                questions.splice(r as unknown as number, 1);
            return questions.length ? out : false;
        },

        validateNewQuestion(question: newQuestion, parent: newQuestion | null){
            const valid = get.valid();
            const { type } = question;
            const fields: ('cat' | 'text' | 'type' | 'fileType')[] = ['text','type'];
            if(question.canBeTop)
                fields.push('cat');
            if(type === 'file')
                fields.push('fileType');
            for(let f of fields)
                valid[f] = Boolean(question[f]?.length);

            if(parent && !this.validateCondition(question, parent))
                valid.condition = false;
            if(isMultiChoice(question) || type === 'objective'){
                const key = type === 'objective' ? 'params' : 'options';
                const obj = question[key];
                const minLen = type === 'objective' ? 1 : 2;

                if(obj.length < minLen)
                    for(let i = obj.length; i < minLen ; i++)
                        obj.push(get.option());
                for(let i in obj)
                    if(!obj[i].val.length)
                        valid[key][i] = true;
            }

            let allInvalid = true;

            for(let v in valid){
                const key = v as keyof newQuestion['valid'];
                const isArrayWithLength = (key === 'options' || key === 'params') && valid[key].length;
                if(valid[key] === false || isArrayWithLength)
                    valid.is = false;

                if(['children','condition','fileType','options', 'is'].includes(key))
                    continue;
                if(!question.canBeTop && v === 'cat')
                    continue;
                /* Originally, the following condition would "break" the loop.
                    This prevented the loop from going through all conditions and setting valid.is to false when necessary. */
                if(valid[key] === true || isArrayWithLength)
                    allInvalid = false;
            }

            return !allInvalid ? valid : null;
        },
        validateCondition(question: newQuestion, parent: newQuestion){
            if(!isMultiChoiceOrNum(parent)) return true;

            const conditions = Object.values(question.condition);
            if(!conditions.length) return false;
                
            for(const condition of conditions){
                const nullOrEmpty = (val: keyof typeof condition) => condition[val] === null || !condition[val].length;

                if(parent.type !== 'num'){
                    if(!condition)
                        return false;
                }else if(nullOrEmpty('val') || (condition.type === '<>' && nullOrEmpty('x'))){
                    return false;
                }
            }

            return true;
        },
    }
});
</script>

<style lang="scss" scoped>
.links {
    & button:hover [class^='material-icons'] {
        transform: scale(1.1) translateY(var(--translate-y));
    }
    & [class^='material-icons'] {
        margin-left: .5rem;
        transition: transform .1s;
        --translate-y: 0;
        transform: translateY(var(--translate-y));

        &.load {
            --translate-y: .1rem;
        }
    }
}
#draftDialog {
    padding: 1.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;

    & button {
        background-color: $swatchB;
        border-radius: $corner;
        padding: .5rem;
        width: 10rem;
        @include shadow;
        width: 100%;

        &:hover {
            background-color: $hoverB;
        }
    }

    & > div {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    & #draftData {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > :nth-child(2n + 1) {
            font-weight: bold;
        }
    }
}

#loadDialog {
    padding: 1rem;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;

    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
        border-bottom: 1px solid #ccc;

        &:first-child {
            border-top: 1px solid #ccc;
        }

        &:hover {
            background-color: $swatchC;
        }

        & > div {
            padding: .5rem;

            &:not(.deleteDraft) {
                width: 15rem;
            }
        }
    }

    & .deleteDraft {
        font-size: 1.3rem;
        transform: translateY(-1px);

        &:hover {
            transform: translateY(-1px) scale(1.1);
        }
        // width: 10%;
    }
}

#validationDialog {
    max-width: 17.8rem;

    & > div {
        padding: 1rem;
    }

    & button {
        background-color: $swatchC;
        width: 100%;
        padding: .5rem 1rem;
        border-top: 1px solid #eee;

        &:hover {
            background-color: $hoverC;
        }
    }
}
</style>
