<template>
    <div class='box data options'>
        <div class='txt'>
            {{ text }}: <a @click='addOptions(elem)'>לחץ כאן להוספה</a>
        </div>
        <div class='optionsList'>
            <span v-for='(option, index) in set.data[elem]' :key='index'>
                <img
                    class='trash'
                    :class='{ inactive: !option.ditch }'
                    :src='"/images/trash-black.png"'
                    @click='deleteOption({ index, elem })'
                />
                <input
                    v-model='option.val'
                    :class='{ error: set.data.valid[elem][index] }'
                    @input='resetValid(index)'
                    @keydown='key === "Enter" ? addOptions(elem) : null'
                />
            </span>
        </div>
        <div class='list-bottom-pad' />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('questions');

export default {
    props: ['text', 'elem'],
    computed: mapState(['set']),
    methods: {
        ...mapMutations(['addOptions']),
        ...mapActions(['deleteOption']),
        resetValid(index){
            this.set.data.valid[this.elem][index] = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.trash {
    width: 0.75rem;
    margin-left: 0.5rem;
    cursor: pointer;

    &.inactive {
        opacity: 0.3;
    }
}

.optionsList {
    width: 100%;
    max-height: 30vh;
    overflow-y: auto;
    padding: 0 1.25rem;

    & .txt {
        width: 100%;
        text-align: center;
        margin: 10px 0px;
    }
    &  input{
        width: 18vw;
        margin: 0.5rem 0.5rem 0 0;
    }
    & span {
        @include flexbox(center-all);
        margin-bottom: 0.7rem;
    }
    & .list-bottom-pad {
        padding: 0.5rem;
    }
}
</style>