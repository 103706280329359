
/*
    -שמירת טיוטה - לשפר UX
    -intake - default without!
    -אפשרות להריץ שאלון של מטופל, כמו במחקר
*/
import SettingsModal from './SettingsModal.vue';
import questionsModule, { setSaves } from './module';
import { defineComponent } from '@vue/runtime-core';
import SavesBar from './SavesBar.vue';

export default defineComponent({
    async beforeMount(){
        if(!this.$store.hasModule('questions'))
            this.$store.registerModule('questions', questionsModule);

        const saves = await this.$request('/questions/saves');
        setSaves(saves.body);
    },
    computed: {
        questions(){
            return this.$store.state.questions!.questions;
        },
        showSettings(){
            return Boolean(this.$store.state.questions!.set);
        }
    },
    components: { SettingsModal, SavesBar }
});
